import { defineStore } from 'pinia'

export const useEntryStore = defineStore('entryStore', {
  state: () => ({
    entry: {},
    selectedEntry: {},
    selectedItems: [],
    items: '',
    minDate: '',
    eventDate: {
      start: '',
      end: '',
    },
    unreads: [],
    follow: 0,
    filters: {},
  }),

  persist: {
    storage: persistedState.sessionStorage,
    paths: ['entry', 'selectedEntry', 'eventDate', 'minDate', 'selectedItems'],
  },

  actions: {
    async fetches(params: any) {
      return await useApi<any>(createUrl('/entries', {
        query: params,
      }))
    },

    async fetchesWith(params: any) {
      return await useApi<any>(createUrl('/entries/with', {
        query: params,
      }))
    },

    async fetchesWithTenantSale(params: any) {
      return await useApi<any>(createUrl('/entries/tenant_sale', {
        query: params,
      }))
    },

    async fetchesTenantDashboard(params: any) {
      return await useApi<any>(createUrl('/entries/tenant_dashboard', {
        query: params,
      }))
    },

    async fetchAdminStatus() {
      return await useApi<any>('/entries/statusAdmin')
    },

    async fetchClientEntryStatus() {
      return await useApi<any>('/entries/statusClient')
    },

    async fetchClientEntryStatusWithDate(params: any) {
      return await useApi<any>('/entries/status_client_date', {
        method: 'POST',
        query: params,
      })
    },

    async fetch(id: number) {
      return await $api(`/entries/${id}`)
    },

    async add(data: any) {
      return await $api('/entries', {
        method: 'POST',
        body: data,
      })
    },

    async update(data: any) {
      return await $api(`/entries/${data.id}`, {
        method: 'PUT',
        body: data,
      })
    },

    async updateWithTenant(data: any) {
      return await $api('/entries/update_with_tenant', {
        method: 'POST',
        body: data,
      })
    },

    async updateProgress(data: any) {
      return await $api(`/entries/update_progress/${data.id}`, {
        method: 'POST',
        body: data,
      })
    },

    async updateFields(data: any) {
      return await $api(`/entries/update_fields/${data.id}`, {
        method: 'PUT',
        body: data,
      })
    },

    async updateMultiple(data: any) {
      return await $api('/entries/update_multiple', {
        method: 'POST',
        body: data,
      })
    },

    async delete(id: number) {
      return await $api(`/entries/${id}`, {
        method: 'DELETE',
      })
    },

    async checkEventDate(data: any) {
      return await $api<boolean>('/entries/check_confirm', {
        method: 'POST',
        body: data,
      })
    },

    async setReadedChat(id: number) {
      return await $api(`/entries/chat/${id}`)
    },

    async followWarning() {
      const { data } = await useApi<any>('/entries/follow')

      this.follow = data.value
    },

    async checkPlaceDuplicate(data: any) {
      return await $api('/entries/check_place_duplicate', {
        method: 'POST',
        body: data,
      })
    },

    async download(data: any) {
      return await $api('/entries/download', {
        params: data,
      })
    },

    async downloadDetail(data: any) {
      return await $api('/entries/download/detail', {
        params: data,
      })
    },

    async downloadDetailNew(data: any) {
      return await $api('/entries/download/detail_new', {
        params: data,
      })
    },

    async downloadTenantSale(data: any) {
      return await $api('/entries/download/tenant_sale', {
        params: data,
      })
    },
  },
})
